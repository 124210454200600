import _domparser from "./domparser";
import _utilities from "./utilities";
var exports = {};
var domparser = _domparser;
var formatDOM = _utilities.formatDOM;
var DIRECTIVE_REGEX = /<(![a-zA-Z\s]+)>/; // e.g., <!doctype html>

/**
 * Parses HTML string to DOM nodes in browser.
 *
 * @param  {string} html  - HTML markup.
 * @return {DomElement[]} - DOM elements.
 */

function HTMLDOMParser(html) {
  if (typeof html !== "string") {
    throw new TypeError("First argument must be a string");
  }

  if (html === "") {
    return [];
  } // match directive


  var match = html.match(DIRECTIVE_REGEX);
  var directive;

  if (match && match[1]) {
    directive = match[1];
  }

  return formatDOM(domparser(html), null, directive);
}

exports = HTMLDOMParser;
export default exports;